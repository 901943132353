import React from 'react';
import Modal from '../components/elements/Modal';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import PartnerLogos from '../components/sections/PartnerLogos';
// import Pricing from '../components/sections/Pricing';
import StoreOnTheGo from '../components/sections/StoreOnTheGo';
import Cta from '../components/sections/Cta';
import ContactForm from '../components/sections/ContactForm';

function Home(props) {
  const {
    contactModalIsOpen,
    openContactModal,
    closeContactModal,
  } = props;

  return (
    <>
      <HeroSplit
        imageFill
        className="illustration-section-01"
        openContactModal={openContactModal}
      />
      <PartnerLogos />
      <FeaturesSplit invertMobile imageFill className="illustration-section-05" />
      <FeaturesTiles />
      <StoreOnTheGo className="illustration-section-04" />
      {/*<Pricing pricingSwitcher className="illustration-section-03" />*/}
      <Cta
        topDivider bottomDivider split
        openContactModal={openContactModal}
      />
      <Modal
        id="contact-modal"
        show={contactModalIsOpen}
        handleClose={closeContactModal}
        contentClassName="py-3 rounded-0"
      >
        <ContactForm closeContactModal={closeContactModal} />
      </Modal>
    </>
  );
};

export default Home;
