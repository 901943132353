import React from 'react';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

function StoreOnTheGo(_props) {
	const {
	  className,
	  topOuterDivider,
	  bottomOuterDivider,
	  topDivider,
	  bottomDivider,
	  hasBgColor,
	  invertColor,
	  invertMobile,
	  invertDesktop,
	  alignTop,
	  imageFill,
	  ...props
	} = {
	  ...SectionSplitProps.defaults,
	  ..._props || {},
	};

	const outerClasses = classNames(
	  'store-on-the-go section',
	  topOuterDivider && 'has-top-divider',
	  bottomOuterDivider && 'has-bottom-divider',
	  hasBgColor && 'has-bg-color',
	  invertColor && 'invert-color',
	  className
	);

	const innerClasses = classNames(
	  'store-on-the-go-inner section-inner',
	  topDivider && 'has-top-divider',
	  bottomDivider && 'has-bottom-divider'
	);

	const sectionHeader = {
	  title: "Your Store, Always Open",
	  paragraph: "Your online reordering will be available to your buyers wherever they go, on their phone, ipad or laptop."
	};

	return (
		<section
			{...props}
		  className={outerClasses}
		>
			<div className="container">
				<div className={innerClasses}>
				  <SectionHeader data={sectionHeader} className="center-content" />
				  <div className="container-xs">
			  		<Image
			  			src={require('./../../assets/images/mockup-iphone-ipad.png')}
			  		/>
				  </div>
				  <Row className="mt-5">
				  	<Col sm={12} md={6}>
				  		<h4>FOR DEALERS</h4>
				  		<p>
								Whether your buyers are in their kitchen, in the front of house, at their office, or working from home, they'll always be able to order brand-approved products from your store.
				  		</p>
				  	</Col>
				  	<Col sm={12} md={6}>
				  		<h4>FOR MANUFACTURERS</h4>
				  		<p>
				  			By having your product info always available with the latest information, more dealers will be interested in working with you and you attract more End Buyers. This means more sales for you, with no extra work required.
				  		</p>
				  	</Col>
				  </Row>
				</div>
			</div>
		</section>
	);
};

export default StoreOnTheGo;
