import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import { SectionProps } from '../../utils/SectionProps';

function Cta(_props) {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    openContactModal,
    ...props
  } = {
    ...SectionProps.defaults,
    split: false,
    ..._props || {},
  };

  const outerClasses = classNames(
    'cta section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan reveal-from-left">
            <h3 className="m-0">
              Are you ready to get online?
            </h3>
          </div>
          <div className="cta-action reveal-from-right">
            <button
              // to="/contact"
              className="button button-primary button-wide-mobile"
              onClick={openContactModal}
            >
              Get started now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
