// import React, { useState } from 'react';
import React from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
// import Modal from '../elements/Modal';

function HeroSplit(_props) {
  // const [videoModalActive, setVideoModalActive] = useState(false);

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    openContactModal,
    ...props
  } = {
    ...SectionSplitProps.defaults,
    ..._props || {},
  };

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="hero-content split-item-content center-content-mobile">
                <h1 className="mt-0 mb-16 reveal-from-left">
                  Reordering Simplified
                </h1>
                <p className="mt-0 mb-24 reveal-from-left" data-reveal-delay="200">
                  Wholesalers, manufacturers, and distributors can unlock growth opportunities for your business by simplifying the reordering process and providing a hassle-free online experience for your customers.
                </p>
                <div
                  className="reveal-from-left"
                  data-reveal-delay="400"
                >
                  <button
                    className="button button-primary button-wide-mobile"
                    onClick={openContactModal}
                  >
                    Get started now
                  </button>
                </div>
              </div>
              <div className="hero-figure is-3d split-item-image illustration-element-01 reveal-scale-up">
                <Image
                  className="has-shadow"
                  // src={require('./../../assets/images/hero-video.png')}
                  src={require('./../../assets/images/catalog-product.png')}
                  alt="Hero"
                  width={528}
                  height={396}
                  style={{cursor: 'pointer'}}
                  // onClick={() => setVideoModalActive(true)}
                />
              </div>
            </div>
          </div>
          {/*<Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={() => setVideoModalActive(false)}
            video="https://www.youtube.com/embed/OLu8foehXN8?rel=0&amp;showinfo=0"
            videoTag="iframe" />*/}
        </div>
      </div>
    </section>
  );
};

export default HeroSplit;
