import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

function FeaturesSplit(_props) {
  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
  } = {
    ...SectionSplitProps.defaults,
    ..._props || {},
  };

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: "No online storefront, No problem",
    paragraph: "With ATERO, you can send your customers a link to login directly to your online reordering platform. Delight your customers with a super simple, hassle-free reordering experience. ATERO is simple to implement and easy for your customers to use."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item reveal-scale-up">
              <div className="split-item-content center-content-mobile">
                <h3 className="mt-0 mb-16">
                  Elevate your brand with a customized online catalog
                </h3>
                <p className="m-0">
                  Quickly create customized product catalogs and pricing for each customer so they find the pre-selected, pre-approved products to order more accurately and have a better experience every time they purchase from you.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image is-3d illustration-element-03',
                  imageFill && 'split-item-image-fill'
                )}>
                <Image
                  src={require('./../../assets/images/catalog.png')}
                  alt="Elevate your brand with a customized online catalog"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item reveal-scale-up">
              <div className="split-item-content center-content-mobile">
                <h3 className="mt-0 mb-16">
                  Simplicity makes loyalty stick
                </h3>
                <p className="m-0">
                  When your customers find a tool that fits into their workflow effortlessly, they're more likely to stick around. They'll rely on it, integrate it into their routines, and voilà, you've got loyalty that's positively sticky.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image is-3d illustration-element-04',
                  imageFill && 'split-item-image-fill'
                )}>
                <Image
                  src={require('./../../assets/images/atero-dash.png')}
                  alt="Simplicity makes loyalty stick"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item reveal-scale-up">
              <div className="split-item-content center-content-mobile">
                <h3 className="mt-0 mb-16">
                  Easier ordering with fast set up & quick ROI
                </h3>
                <p className="m-0">
                  Instantly sell all your products online. Atero does the work to populate your store with all your products so you can start selling right away.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image is-3d illustration-element-05',
                  imageFill && 'split-item-image-fill'
                )}>
                <Image
                  src={require('./../../assets/images/catalog-product.png')}
                  alt="Easier Ordering with Fast set up & quick ROI"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSplit;
