import React, { useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const LayoutDefault = ({ children }) => {
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

  const openContactModal = (e) => {
    e && e.preventDefault && e.preventDefault();
    setContactModalIsOpen(true);
  };

  const closeContactModal = (e) => {
    e && e.preventDefault && e.preventDefault();
    setContactModalIsOpen(false);
  };

  return (
    <>
      <Header
        navPosition="right"
        openContactModal={openContactModal}
      />
      <main className="site-content">
        {React.cloneElement(children, {
          contactModalIsOpen: contactModalIsOpen,
          openContactModal: openContactModal,
          closeContactModal: closeContactModal,
        })}
      </main>
      <Footer className="illustration-section-08 reveal-from-bottom" data-reveal-offset="0" />
    </>
  )
};

export default LayoutDefault;
