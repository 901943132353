import React from 'react';
import Button from '../elements/Button';
import Input from '../elements/Input';
import useScript from '../../hooks/useScript';

function ContactForm(props) {
	const { closeContactModal } = props;

	useScript("https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=1734ce1c21ec0b8aaa584ff0ef89bcd5b2d04c44c070cc94dd19cbdc452b4adfgid337bb2cfb46366eda20b70ab99932162c30acc91b2facc4c85b1f42ede39b609gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a");

	return (
		<div
			id="crmWebToEntityForm"
			className="zcwf_lblLeft crmWebToEntityForm"
		>
			<h3 className="mt-0 mb-2">Get in touch</h3>
			<form
				action="https://crm.zoho.com/crm/WebToLeadForm"
				name="WebToLeads5605547000000579003"
				method="POST"
				acceptCharset="UTF-8"
			>
				{/*Hidden items*/}
				<Input
					type="text"
					id="xnQsjsdp"
					name="xnQsjsdp"
					value="337bb2cfb46366eda20b70ab99932162c30acc91b2facc4c85b1f42ede39b609"
					readOnly={true}
					className="d-none"
				/>
				<Input
					type="text"
					id="zc_gad"
					name="zc_gad"
					value=""
					readOnly={true}
					className="d-none"
				/>
				<Input
					type="text"
					id="xmIwtLD"
					name="xmIwtLD"
					value="1734ce1c21ec0b8aaa584ff0ef89bcd5b2d04c44c070cc94dd19cbdc452b4adf"
					readOnly={true}
					className="d-none"
				/>
				<Input
					type="text"
					id="actionType"
					name="actionType"
					value="TGVhZHM="
					readOnly={true}
					className="d-none"
				/>
				<Input
					type="text"
					id="returnURL"
					name="returnURL"
					value="https://atero.io/"
					readOnly={true}
					className="d-none"
				/>
				{/*Visible*/}
				<div className="mt-0">
					<Input
						type="text"
						id="First_name"
						name="First Name"
						label="First name"
						maxLength="40"
						required={true}
					/>
				</div>
				<div className="mt-1">
					<Input
						type="text"
						id="Last_name"
						name="Last Name"
						label="Last name"
						maxLength="80"
						required={true}
					/>
				</div>
				<div className="mt-1">
					<Input
						type="text"
						id="Company"
						name="Company"
						label="Company"
						maxLength="200"
						required={true}
					/>
				</div>
				<div className="mt-1">
					<Input
						type="email"
						id="Email"
						name="Email"
						label="Email"
						maxLength="100"
						required={true}
					/>
				</div>
				<Button
					type="submit"
					color="primary"
					className="mt-3 mb-2"
				>
					Submit
				</Button>
			</form>
		</div>
	);
};

export default ContactForm;
