import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from '../elements/Image';

const images = [
	{
		alt: 'Admiral Craft',
		src: require('./../../assets/images/logos/admiralcraft.png'),
	},
	{
		alt: 'Alluserv',
		src: require('./../../assets/images/logos/alluserv.png'),
	},
	{
		alt: 'Avondale Restaurant Equipment',
		src: require('./../../assets/images/logos/avondale.png'),
	},
	{
		alt: 'Cambro',
		src: require('./../../assets/images/logos/cambro.png'),
	},
	{
		alt: 'Carlisle Sanitary',
		src: require('./../../assets/images/logos/carlislesanitary.png'),
	},
	{
		alt: 'True Manufacturing Group',
		src: require('./../../assets/images/logos/truemfg.png'),
	},
];

function PartnerLogos() {
	return (
		<div className="partner-logos section center-content-mobile">
			<Container className="border-top py-3 d-none d-sm-block">
				<Row>
					{images.map((imgProps, idx) => (
						<Col key={idx} xs={2}>
							<Image
								{...imgProps}
							/>
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
};

export default PartnerLogos;
